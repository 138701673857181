import actions from '../constants/index';
import { createSlice } from '@reduxjs/toolkit'

export const twadSlice = createSlice({
  name: 'twad',
  initialState: {
    token:'',userName:'',password:'',userID:'',userRole:'',hodId:-1,
    userDisplayName:'',profile_pic:'',userType:0,userMobile:'',userID:'',arrSeries:[],value:0,message:'',UMISdistrictId:-1,
    sidebarShow:true,portal:'adtw',instId:-1,districtId:-1,roleId:-1,deptId:-1,section:'',instKey:'',studentKey:'',adtwInstId:'',dash:{pending:'-'}
},
  reducers: {
    login: (state,msg) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.message = msg.payload.message
      state.userName=msg.payload.userName
      state.password=msg.payload.password
      state.userRole=msg.payload.userRole
      state.userID = msg.payload.userID
      state.userDisplayName = msg.payload.userDisplayName
      state.portal = msg.payload.portal
      state.instId = msg.payload.instId
      state.roleId = msg.payload.roleId
      state.districtId = msg.payload.districtId
      state.deptId = msg.payload.deptId
      state.section = msg.payload.section
      state.instKey = msg.payload.instKey
      state.studentKey = msg.payload.studentKey
      state.adtwInstId = msg.payload.adtwInstId
      state.umisInstId = msg.payload.umisInstId
      state.hodId = msg.payload.hodId
      state.UMISdistrictId = msg.payload.UMISdistrictId





    },
  
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    setSidebar: (state) => {
      state.sidebarShow = !state.sidebarShow
    },
    dashboard: (state,msg) => {
      state.dash.pending = msg.payload.pending
      //state.studentKey = msg.payload.studentKey
    },
  },
})

// Action creators are generated for each case reducer function
export const { login, decrement, incrementByAmount,setSidebar,dashboard } = twadSlice.actions

export default twadSlice.reducer