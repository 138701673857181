import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import Config from './tnssp/configs/StatusConfig'
import './scss/style.scss'
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const StudentLogin = React.lazy(() => import('./tnssp/logins/StudentLogin'))
const StudentLoginStage = React.lazy(() => import('./tnssp/logins/StudentLoginStage'))
const InstLogin = React.lazy(() => import('./tnssp/logins/InstLogin'))
const InstLoginStage = React.lazy(() => import('./tnssp/logins/InstLoginStage'))
const WelfareLogin = React.lazy(() => import('./tnssp/logins/WelfareLogin'))
const WelfareLoginStage = React.lazy(() => import('./tnssp/logins/WelfareLoginStage'))
const AdminLogin = React.lazy(() => import('./tnssp/logins/AdminLogin'))
const EducationLogin = React.lazy(() => import('./tnssp/logins/EducationLogin'))
const Grievance=React.lazy(() => import('./tnssp/Greive/Greivance'))

const HomeMain = React.lazy(() => import('./tnssp/HomeMain'))

class App extends Component {

 

  render() {
    let route= <HashRouter>
    <Suspense fallback={loading}>
   
      <Routes>
        
      
        <Route exact path="/login" name="Login" element={<Login />} />
        <Route exact path="/studentLogin" name="Login" element={<StudentLogin />} />
        <Route exact path="/InstLogin" name="Login" element={<InstLogin />} />
        <Route exact path="/WelfareLogin" name="Login" element={<WelfareLogin />} />
        <Route exact path="/AdminLogin" name="Login" element={<AdminLogin />} />
        <Route exact path="/Grievance" name="Login" element={<Grievance />} />
        <Route exact path="/EducationLogin" name="Login" element={<EducationLogin />} />
        
        <Route  path="/" name="Home" element={<HomeMain />} />
       
        <Route exact path="/*" name="Home1" element={ <DefaultLayout />}/>
      </Routes>
     
    </Suspense>
  </HashRouter>;
    if(Config.Mode==0){
      route= <HashRouter>
      <Suspense fallback={loading}>
     
        <Routes>
       
        
          <Route exact path="/login" name="Login" element={<Login />} />
          <Route exact path="/studentLogin" name="Login" element={<StudentLogin />} />
          <Route exact path="/InstLogin" name="Login" element={<InstLoginStage/>} />
          <Route exact path="/WelfareLogin" name="Login" element={<WelfareLoginStage />} />
          <Route exact path="/AdminLogin" name="Login" element={<AdminLogin />} />
          <Route exact path="/Grievance" name="Login" element={<Grievance />} />
          <Route exact path="/EducationLogin" name="Login" element={<EducationLogin />} />
          <Route  path="/" name="Home" element={<HomeMain />} />
         
          <Route exact path="/*" name="Home1" element={ <DefaultLayout />}/>
        </Routes>
       
      </Suspense>
    </HashRouter>

    }
    return (
     
     route
    
    )
  }
}

export default App
