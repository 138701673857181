var StatusConfig={};

//0-Stage
//1-PROD
StatusConfig.Mode=1

StatusConfig.ScholorshipApprovalProcess={bcmbc:[{},
    {},
    {current_status:16,approve:17,reject:23,withheld:16},
    {current_status:17,approve:18,reject:23,withheld:17},
    {reject:20,approve:15,withheld:14,current_status:14},
    {reject:20,approve:16,withheld:15,current_status:15},
    {reject:20,approve:19,withheld:18,current_status:18},
    {},{},{reject:20,approve:2,withheld:1,current_status:1},//9
    {reject:20,approve:3,withheld:2,current_status:2},{},{},{},
    {reject:20,approve:19,withheld:18,current_status:18}, 
    {reject:20,approve:19,withheld:18,current_status:18}
],adtw:[{},
    {},
    {current_status:30,approve:17,reject:23,withheld:16},
    {current_status:16,approve:18,reject:23,withheld:17},
    {reject:20,approve:17,withheld:30,current_status:30},
    {reject:20,approve:16,withheld:15,current_status:15},
    {reject:20,approve:19,withheld:18,current_status:18},
    {},{reject:20,approve:16,withheld:15,current_status:15},
    {reject:20,approve:18,withheld:15,current_status:15},
    {reject:20,approve:21,withheld:15,current_status:16},//10
    {reject:20,approve:16,withheld:15,current_status:15},{},{},
    {reject:20,approve:19,withheld:18,current_status:18},{reject:20,approve:21,withheld:16,current_status:16},
    {reject:20,approve:19,withheld:18,current_status:18}
],
dls:[{},
    {},
    {current_status:30,approve:17,reject:23,withheld:16},
    {current_status:17,approve:18,reject:23,withheld:17},
    {reject:20,approve:31,withheld:30,current_status:30},
    {reject:20,approve:16,withheld:15,current_status:15},
    {reject:20,approve:19,withheld:18,current_status:18},
    {},{reject:20,approve:16,withheld:15,current_status:15},
    {reject:20,approve:18,withheld:15,current_status:15},
    {reject:20,approve:21,withheld:15,current_status:16},//10
    {reject:20,approve:16,withheld:15,current_status:15},{},{},
    {reject:20,approve:19,withheld:18,current_status:18},{},
    {reject:20,approve:19,withheld:18,current_status:18}
]}

StatusConfig.StatusList=[{},{},{},{},{},{},{},{},{},{},{},{},{},{status:'ttt'},
    {status:'Application Submitted'},
    ,{status:'Application Approved By Assistant'},
    ,{status:'Application Approved  By Principal'},
    ,{status:'Application Recomended by Dist. Asst. BCMBC'},
    ,{status:'Application Submitted'},
    ,{status:'Application Submitted'},
    ,{status:'Application Submitted'},
    ,{status:'Application Submitted'},
    ,{status:'Application Submitted'},
    ,{status:'Application Submitted'},
    ,{status:'Application Submitted'}]

module.exports=StatusConfig; 


